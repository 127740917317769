















import { Component, Vue } from "vue-property-decorator";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

@Component({
    components: {
        PageTitleHeader
    }
})
export default class ScheduleView extends Vue {

   get textHeader() {
        return this.$store.getters.getPageOptions("schedule").textHeader
            ? this.$store.getters.getPageOptions("schedule").textHeader
            : "SCHEDULE";
    }
}
